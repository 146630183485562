:root {
    --linkSize: 10px;
}

.arrowLink {
    display: flex;
    align-items: center;
}

.arrowLink > a {
    height: var(--linkSize);
    display: flex;
    align-items: center;
    color: var(--grey);
    transition: all .75s ease;
    font-size: var(--linkSize);
}

.arrowLink__arrow {
    color: var(--mint);
    rotate: -45deg;
    scale: .5;
}

.arrowLink:hover > a {
    color: var(--pearl);
    transition: all .75s ease;
}

.arrowLink:hover .arrowLink__arrow {
    rotate: 0deg;
    transition: all .5s ease;
}

@media only screen and (min-width: 480px) {
    :root {
        --linkSize: 12px;
    }
    .arrowLink__arrow {
        scale: .65;
    }
}

@media only screen and (min-width: 768px) and (min-height: 768px) {
    :root {
        --linkSize: 16px;
    }

    .arrowLink__arrow {
        scale: .8;
    }
}