:root {
  --mint: #32ec87;
  --pearl: #d9d7dd;
  --grey: #69747c;
  --black: #08090a;
  --largeRadius: 2em;
  --normalRadius: 1em;
  --headerHeight: 8vh;
  --pageHeight: 92vh;
  --contentWidth: 95vw;
  --sideMargin: 2.5vw;
  --aboutCardWidth: 95vw;
  --minWidth: 16em;
  --smallBorder: .5px solid var(--mint);
  --normalBorder: 1px solid var(--mint);
  --largeBorder: 2.5px solid var(--mint);
}

.mint {
  color: var(--mint)
}

.centered {
  width: 90vw;
  margin: auto;
}

.loading {
  height: calc(100vh - var(--headerHeight));
  display: flex;
  align-items: center;
  justify-content: center;
}

a {
  text-decoration: none;
  color: var(--pearl);
}

h1 {
  font-size: 44px;
  margin: 0;
}

h2 {
  font-size: 34px;
  margin: 0;
}

h3 {
  font-weight: 500;
  color: var(--mint);
}

h4 {
  font-weight: 400;
  font-size: 30px;
  margin: 0;
}

h5 {
  font-weight: 400;
  font-size: 24px;
  margin: 0;
}

p {
  font-size: 14px;
  letter-spacing: 1px;
  word-spacing: 2px;
  line-height: 24px;
}

text {
  color: var(--grey)
}

@media only screen and (min-width: 378px) {
  :root {
    --headerHeight: 4em;
  }

  h5 {
    font-size: 28px;
  }
}

@media only screen and (min-width: 480px) {
  :root {
    --contentWidth: 90vw;
    --sideMargin: 5vw;
  }
  h1 {
    font-size: 55px;
  }
  h4 {
    font-size: 34px;
  }
  p {
    font-size: 14px;
  }
}

@media only screen and (min-width: 768px) {
  :root {
    --aboutCardWidth: 85vw;
  }
  h1 {
    font-size: 66px;
  }
  h2 {
    font-size: 44px;
  }
  h4 {
    font-size: 40px
  }
  p {
    font-size: 16px;
    letter-spacing: 1.1px;
    word-spacing: 3px;
    line-height: 26px;
  }
}

@media only screen and (min-width: 1024px) {
  :root {
    --aboutCardWidth: 80vw;
  }
  h2 {
    font-size: 50px;
  }
  h4 {
    font-size: 46px;
  }
  p {
    font-size: 20px;
    letter-spacing: 1.25px;
    word-spacing: 4px;
    line-height: 30px;
  }
}

@media only screen and (min-width: 1200px) {
  h1 {
    font-size: 88px;
  }
  h2 {
    font-size: 66px;
  }
  h4 {
    font-size: 52px;
  }
}