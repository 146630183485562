.header {
    height: var(--headerHeight);
    width: 100vw;
    min-width: var(--minWidth);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    /* overflow: hidden; */
    z-index: 11;
}

.header__name {
    display: none;
}

.nav__line {
    height: 2px;
    border-radius: 2px;
    background: var(--mint);
    filter: drop-shadow(0px 3px 3px var(--mint));
    margin-bottom: 6px;
    transition: all .5s ease;
}

.nav__link {
    transition: all 1s ease;
    font-size: 12px;
    color: var(--grey);
}

.nav__link:hover {
    cursor: pointer;
    transition: all 1s ease;
    text-shadow: 0px 0px 8px var(--mint);
    color: var(--mint);
}

.header__navigation {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: var(--headerHeight);
    gap: 1.5em;
    z-index: 10;
    background: rgba(0, 0, 0, .2);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
}

.nav__linksContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

}

@media only screen and (min-width: 378px) {
    .nav__link {
        font-size: 16px;
    }
}
@media only screen and (min-width: 768px) {
    .header__name {
        display: unset;
        color: var(--mint);
        position: absolute;
        left: var(--sideMargin);
        z-index: 11;
    }
}