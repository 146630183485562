.footer {
    width: var(--contentWidth);
    padding-left: var(--sideMargin);
    padding-right: var(--sideMargin);
    height: var(--headerHeight);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    min-width: var(--minWidth);
    background: rgba(0, 0, 0, .2);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
}

.footer__text {
    display: flex;
    flex-direction: column;
    font-size: 7px;
    width: 40%;
    height: 100%;
    justify-content: space-evenly;
}

.footer__links {
    display: flex;
    width: 50%;
    height: 90%;
    justify-content: space-around;
}

.footer__linksWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

@media only screen and (min-width: 480px) {
    .footer__text {
        font-size: 10px;
    }
}

@media only screen and (min-width: 768px) and (min-height: 768px) {
    .footer__text {
        font-size: 14px;
    }
}